import React from "react";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import QTeamPage from "../queries/QTeamPage";
import TeamPage from "../components/team-page";

const Team = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <QTeamPage>
      {({ teamPage }) => (
        <Layout>
          <SEO title={teamPage.pageTitle} />
          <TeamPage {...teamPage} />
        </Layout>
      )}
    </QTeamPage>
  );
};

export default Team;
